<template>
  <div>
    <navbar title="考试申报"></navbar>
    <div class="box" v-if="list.length > 0">
      <div v-for="(item, index) in list" :key="index">
        <router-link
          v-if="item.style == 1"
          :to="`/policydetails?id=${item.id}`"
          class="item1"
        >
          <img :src="item.picture_two" alt="" />
          <div class="item1_title">
            {{ item.title }}
          </div>
          <div class="footer">
            <div><img src="../assets/time.png" alt="" />{{ item.time }}</div>
            <div class="right">
              <div>
                <img src="../assets/yanjing.png" alt="" />{{ item.views }}
              </div>
              <div>
                <img src="../assets/zhuanfa_icon@2x.png" alt="" />{{
                  item.forward
                }}
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="item.style == 2"
          :to="`/policydetails?id=${item.id}`"
          class="item2"
        >
          <img class="img" :src="item.picture_one" alt="" />
          <div class="item2_right">
            <div class="title">{{ item.title }}</div>
            <div class="conter">
              {{ item.profiles }}
            </div>
            <div class="footer">
              <div><img src="../assets/time.png" alt="" />{{ item.time }}</div>
              <div class="right">
                <div>
                  <img src="../assets/yanjing.png" alt="" />{{ item.views }}
                </div>
                <div>
                  <img src="../assets/zhuanfa_icon@2x.png" alt="" />{{
                    item.forward
                  }}
                </div>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="item.style == 3"
          :to="`/policydetails?id=${item.id}`"
          class="item3"
        >
          <div class="title">{{ item.title }}</div>
          <div class="conter">
            {{ item.profiles }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="tac" v-if="list.length > 0">暂无更多</div>
    <div v-if="list.length == 0" class="tac">——— 暂无内容 ———</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      list: [],
    };
  },
  methods: {
    ...mapActions(["getnews"]),
  },
  mounted() {
    this.getnews({
      page: 1,
      pageSize: 1000,
      type: 6,
    }).then((res) => {
      if (res.SuccessCode == 200) {
        res.ResponseBody.data.forEach((e) => {
          e.time = e.time.substring(0, 10);
        });
        this.list = res.ResponseBody.data;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.item1 {
  display: block;
  margin-bottom: 0.45rem;
  img {
    width: 7rem;
    height: 2.4rem;
  }
  .item1_title {
    width: 7rem;
    margin-top: 0.1rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .footer {
    margin-top: 0.08rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #cdcdcd;
    .right {
      display: flex;
      div {
        margin-left: 0.33rem;
      }
    }
    img {
      margin-right: 0.1rem;
      width: 0.22rem;
      height: auto;
    }
  }
}
.item2 {
  display: block;
  margin-bottom: 0.45rem;
  display: flex;
  .img {
    width: 2.07rem;
    height: 1.54rem;
    margin-right: 0.2rem;
  }
  .item2_right {
    .title {
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      margin-bottom: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .conter {
      font-size: 0.28rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #7c7c7c;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .footer {
      margin-top: 0.08rem;
      display: flex;
      justify-content: space-between;
      font-size: 0.24rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #cdcdcd;
      .right {
        display: flex;
        div {
          margin-left: 0.33rem;
        }
      }
      img {
        margin-right: 0.1rem;
        width: 0.22rem;
        height: auto;
      }
    }
  }
}
.item3 {
  display: block;
  margin-bottom: 0.45rem;
  .title {
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .conter {
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #7c7c7c;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
</style>